import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);

export const updateCategories = createAsyncThunk(
  "categories/updateCategories",
  async ({ url, data, token }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);
export const deleteCategories = createAsyncThunk(
  "categories/deleteCategories",
  async ({ url, token }) => {
    try {
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);

export const getActivities = createAsyncThunk(
  "getTimeEntry",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);

export const updateActivity = createAsyncThunk(
  "categories/updateActivity",
  async ({ url, data, token }) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);
const ActivitySlice = createSlice({
  name: "activity",
  initialState: {
    allCategories: null,
    allActivities: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.allCategories = action?.payload;
      })
      .addCase(getCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getActivities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        state.loading = false;
        state.allActivities = action?.payload;
      })
      .addCase(getActivities.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }),
});

export const ActivityReducer = ActivitySlice.reducer;
