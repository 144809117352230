import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getLoginUserData = createAsyncThunk(
  "getLoginUserData",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);

export const getFirmData = createAsyncThunk(
  "getFirmData",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      toast.error("Failed to get any firm");
      throw error.response?.data || error.message;
    }
  }
);

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    firmData: null,
    LoginUserData: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFirmData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFirmData.fulfilled, (state, action) => {
        state.loading = false;
        state.firmData = action?.payload;
      })
      .addCase(getFirmData.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Failed to get any firm";
      })
      .addCase(getLoginUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLoginUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.LoginUserData = action?.payload;
      })
      .addCase(getLoginUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "Failed to get any user data";
      });
  },
});

export const DashboardReducer = DashboardSlice.reducer;
