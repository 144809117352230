import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import styles, {
  generateUniqueMatterName,
  pageScrollStyle,
} from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { backend_api } from "../../../static/server";
import {
  getCases,
  getPracticeArea,
} from "../../../redux/Apps/Lawyerapp/CaseSlice/CaseSlice";
import { useParams } from "react-router-dom";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { caseFinancialData } from "../../../static/staticdata";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import UserAvatar from "../../../components/UserAvatar";
import { getContactDetail } from "../../../redux/Apps/Lawyerapp/ContactSlice/ContactSlice";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

const CustomAccordion = ({ expanded, onChange, title, children }) => {
  return (
    <Accordion
      disableGutters
      elevation={0}
      square
      expanded={expanded}
      onChange={onChange}
      sx={{
        borderRadius: "0.6rem",
        border: "1px solid #e5e5e5",
        backgroundColor: "#fff",
        boxShadow: "none",
        "&::before": { display: "none" },
      }}
    >
      <AccordionSummary
        expandIcon={<KeyboardArrowDownRounded />}
        sx={{
          padding: "0.5rem 1rem",
          borderBottom: (theme) => (expanded ? "1px solid #e5e5e5" : "none"),
          minHeight: "unset",
          "& .MuiAccordionSummary-content": {
            margin: "0rem",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: "1.1rem", fontWeight: 600 }}
        >
          {title}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ padding: "0rem", pt: "0.5rem" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

const CaseDashboard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { token } = useSelector((data) => data.user);
  const { allCase, loading } = useSelector((state) => state.case);
  const [budgetExpanded, setBudgetExpanded] = useState(true);
  const [contactExpand, setContactExpand] = useState(true);
  const [matterExpand, setMatterExpand] = useState(true);
  const [customFieldExpand, setCustomFieldExpand] = useState(true);
  const [timeLineExpend, settimeLineExpend] = useState(true);
  const [contactDetailExpand, setContactDetailExpand] = useState(false);
  const { contactDetail } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(
      getCases({ url: `${backend_api}case/register/${id}/`, token: token })
    );

    dispatch(
      getPracticeArea({
        url: `${backend_api}case/case-type/?case_service_type=practice_area`,
        token: token,
      })
    );
  }, [dispatch, id, token]);

  useEffect(() => {
    if (allCase?.case_related_person) {
      dispatch(
        getContactDetail({
          url: `${backend_api}contact/retrieve/${allCase?.case_related_person}/`,
          token: token,
        })
      );
    }
  }, [dispatch, token, allCase?.case_related_person]);
  console.log(contactDetail);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="bg-white rounded-lg items-center shadow-md p-2">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 className={`${styles.title}`}>
                {generateUniqueMatterName(allCase?.id, allCase?.case_name)}
              </h2>
              <Box className="flex gap-4">
                <Button
                  variant="contained"
                  sx={{ textTransform: "capitalize" }}
                >
                  Edit Matter
                </Button>
              </Box>
            </Box>
          </div>
          <div>
            <div className="flex items-start justify-between w-full ">
              <div className="w-full">
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  <Box
                    className="bg-white rounded-lg h-[80vh] p-2 w-full shadow-sm"
                    sx={{
                      ...pageScrollStyle,
                      overflow: "auto",
                      p: "1rem",
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        width: "65%",
                      }}
                    >
                      <Box>
                        <CustomAccordion
                          expanded={budgetExpanded}
                          onChange={() => setBudgetExpanded(!budgetExpanded)}
                          title={`Financial Record of
     ${generateUniqueMatterName(allCase?.id, allCase?.case_name)}`}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.5rem",
                            }}
                          >
                            <Box sx={{ display: "flex", gap: "1rem" }}>
                              <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                  {caseFinancialData.map((item, i) => (
                                    <Grid key={i} item xs={6} md={6}>
                                      <Box
                                        className="bg-white rounded-md shadow-md"
                                        sx={{
                                          border: "1px solid #e5e5e5",
                                          borderRadius: "0.5rem",
                                          p: "0.5rem",
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "0.5rem",
                                          height: "7rem", // ✅ Fixed height
                                          justifyContent: "space-between", // ✅ Align content
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "1rem",
                                            }}
                                          >
                                            {item.title}
                                          </Typography>
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "1rem",
                                            }}
                                          >
                                            {`$${item.num.toFixed(2)}`}
                                          </Typography>
                                        </Box>

                                        {item.usedBudget && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: "0.5rem",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: "0.5rem",
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                sx={{ color: "#999" }}
                                              >
                                                Used Budget:
                                              </Typography>
                                              <Typography variant="body2">
                                                {item.usedBudget}
                                              </Typography>
                                            </Box>
                                            <Box>
                                              <LinearProgress
                                                variant="determinate"
                                                value={item.usedBudget}
                                                sx={{
                                                  height: "8px",
                                                  borderRadius: "0.5rem",
                                                  backgroundColor: "#f0f0f0",
                                                  "& .MuiLinearProgress-bar": {
                                                    backgroundColor: "#1e4620",
                                                  },
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        )}

                                        {item.unbilled && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              gap: "0.5rem",
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              sx={{ color: "#999" }}
                                            >
                                              Unbilled:
                                            </Typography>
                                            <Typography variant="body2">
                                              {item.unbilled}
                                            </Typography>
                                          </Box>
                                        )}
                                        {item.minTrustHold && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              gap: "0.5rem",
                                            }}
                                          >
                                            <Typography
                                              variant="body2"
                                              sx={{ color: "#999" }}
                                            >
                                              Minimum Trust Hold:
                                            </Typography>
                                            <Typography variant="body2">
                                              {item.minTrustHold}
                                            </Typography>
                                          </Box>
                                        )}

                                        {item.btnText && (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "flex-start",
                                              mt: "0.5rem",
                                            }}
                                          >
                                            <Button
                                              variant="outlined"
                                              size="small"
                                              sx={{
                                                textTransform: "capitalize",
                                                fontSize: "0.75rem",
                                                borderRadius: "0.5rem",
                                              }}
                                            >
                                              {item.btnText}
                                            </Button>
                                          </Box>
                                        )}
                                      </Box>
                                    </Grid>
                                  ))}
                                  <Grid item xs={12}>
                                    <Box
                                      className="bg-white rounded-md shadow-md"
                                      sx={{
                                        border: "1px solid #e5e5e5",
                                        borderRadius: "0.5rem",
                                        p: "1rem",
                                        display: "flex",
                                        gap: "1rem",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "1rem",
                                          width: "50%",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontSize: "1rem",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Time Tracking
                                          </Typography>
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            sx={{
                                              textTransform: "capitalize",
                                              fontSize: "0.75rem",
                                            }}
                                          >
                                            Add Time
                                          </Button>
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.5rem",
                                          }}
                                        >
                                          <Box>
                                            <LinearProgress
                                              variant="determinate"
                                              value={40}
                                              sx={{
                                                height: "8px",
                                                borderRadius: "0.5rem",
                                                backgroundColor: "#f0f0f0",
                                                "& .MuiLinearProgress-bar": {
                                                  backgroundColor: "#1e4620",
                                                },
                                              }}
                                            />
                                          </Box>

                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: 500,
                                                color: "text.secondary",
                                              }}
                                            >
                                              Total Time: 4 Hours
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontSize: "0.85rem",
                                                fontWeight: 500,
                                                color: "#666",
                                              }}
                                            >
                                              Unbilled Time: 2 Hours
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontSize: "0.85rem",
                                                fontWeight: 500,
                                                color: "#666",
                                              }}
                                            >
                                              Total Amount: $450.00
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "1rem",
                                          width: "50%",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontSize: "1rem",
                                              fontWeight: 600,
                                            }}
                                          >
                                            Expenses
                                          </Typography>
                                          <Button
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                            sx={{
                                              textTransform: "capitalize",
                                              fontSize: "0.75rem",
                                            }}
                                          >
                                            Add Expenses
                                          </Button>
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "0.5rem",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              variant="body1"
                                              sx={{
                                                fontSize: "0.9rem",
                                                fontWeight: 500,
                                                color: "text.secondary",
                                              }}
                                            >
                                              Total Expenses:
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                fontSize: "0.85rem",
                                                fontWeight: 500,
                                                color: "#666",
                                              }}
                                            >
                                              0.00
                                            </Typography>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Box>
                        </CustomAccordion>
                      </Box>
                      <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Box sx={{ width: "60%" }}>
                          <CustomAccordion
                            expanded={matterExpand}
                            onChange={() => setMatterExpand(!matterExpand)}
                            title={" Matter Details"}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  p: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  Matter Description:
                                </Typography>
                                <Typography variant="body1">--</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  p: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  Responsible attorney:
                                </Typography>
                                <Typography variant="body1">--</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  p: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  Permissions:
                                </Typography>
                                <Typography variant="body1">--</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  p: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  Blocked users:
                                </Typography>
                                <Typography variant="body1">--</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  p: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  Originating attorney:
                                </Typography>
                                <Typography variant="body1">--</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "1rem",
                                  p: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontSize: "0.9rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  Matter notifications:
                                </Typography>
                                <Typography variant="body1">--</Typography>
                              </Box>
                            </Box>
                          </CustomAccordion>
                        </Box>
                        <Box sx={{ width: "40%" }}>
                          <CustomAccordion
                            expanded={customFieldExpand}
                            onChange={() =>
                              setCustomFieldExpand(!customFieldExpand)
                            }
                            title="Custom Field"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                              }}
                            ></Box>
                          </CustomAccordion>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "35%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <Box>
                        <CustomAccordion
                          expanded={contactExpand}
                          onChange={() => setContactExpand(!contactExpand)}
                          title="Connected Contacts"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.5rem",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                                borderRadius: "1rem",
                                boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                                p: "0.5rem",
                                height: contactDetailExpand
                                  ? "10rem"
                                  : "fit-content",
                                transition: "height 0.2s",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  gap: "1rem",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "1rem",
                                    zIndex: 101,
                                  }}
                                >
                                  <UserAvatar
                                    // firstName={LoginUserData?.first_name}
                                    // imgSrc={LoginUserData?.avatar}
                                    height={"2.5rem"}
                                    width={"2.5rem"}
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0.2rem",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: 500,
                                        color: "white",
                                      }}
                                    >
                                      sajan
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.8rem",
                                        fontWeight: 500,
                                      }}
                                    >
                                      client
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={() =>
                                      setContactDetailExpand(
                                        !contactDetailExpand
                                      )
                                    }
                                  >
                                    <KeyboardArrowDownRounded />
                                  </IconButton>
                                </Box>
                              </Box>
                              {contactDetailExpand && (
                                <Box>
                                  <Divider />
                                  <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Phone:
                                    </Typography>
                                    <Typography variant="body1">--</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Email:
                                    </Typography>
                                    <Typography variant="body1">--</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Address:
                                    </Typography>
                                    <Typography variant="body1">--</Typography>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                                borderRadius: "0.5rem",
                                boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
                                p: "0.5rem",
                                height: contactDetailExpand
                                  ? "10rem"
                                  : "fit-content",
                                transition: "height 0.2s",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  gap: "1rem",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "1rem",
                                    zIndex: 101,
                                  }}
                                >
                                  <UserAvatar
                                    // firstName={LoginUserData?.first_name}
                                    // imgSrc={LoginUserData?.avatar}
                                    height={"2.5rem"}
                                    width={"2.5rem"}
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0.2rem",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: 500,
                                        color: "white",
                                      }}
                                    >
                                      sajan
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.8rem",
                                        fontWeight: 500,
                                      }}
                                    >
                                      client
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <IconButton
                                    onClick={() =>
                                      setContactDetailExpand(
                                        !contactDetailExpand
                                      )
                                    }
                                  >
                                    <KeyboardArrowDownRounded />
                                  </IconButton>
                                </Box>
                              </Box>
                              {contactDetailExpand && (
                                <Box>
                                  <Divider />
                                  <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Phone:
                                    </Typography>
                                    <Typography variant="body1">--</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Email:
                                    </Typography>
                                    <Typography variant="body1">--</Typography>
                                  </Box>
                                  <Box sx={{ display: "flex", gap: "1rem" }}>
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontSize: "0.9rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Address:
                                    </Typography>
                                    <Typography variant="body1">--</Typography>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </CustomAccordion>
                      </Box>
                      <Box>
                        <CustomAccordion
                          expanded={timeLineExpend}
                          onChange={() => settimeLineExpend(!timeLineExpend)}
                          title="Time Line(s)"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.5rem",
                            }}
                          ></Box>
                        </CustomAccordion>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default CaseDashboard;
