import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import styles, {
  generateUniqueMatterName,
  pageScrollStyle,
} from "../../../styles/styles";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import DataTable from "../../DataTable";
import moneytranferImg from "../../../assets/money-tranfer.webp";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { CloseRounded } from "@mui/icons-material";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { getAllFilterContacts } from "../../../redux/Apps/Lawyerapp/ContactSlice/ContactSlice";
import { backend_api } from "../../../static/server";
import { useDispatch, useSelector } from "react-redux";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import { currencyList } from "../../../static/staticdata";
import {
  getAllStatement,
  getBankDetail,
} from "../../../redux/Apps/Lawyerapp/AccountSlice/AccountSlice";
import { useDialog } from "../../DialogContext";
import NewTransactionForm from "./AccountsForms/NewTransactionForm";
import TranferFundFrom from "./AccountsForms/TranferFundFrom";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const CustomNoRowsOverlay = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      p: "2rem",
      gap: "1rem",
    }}
  >
    <Box sx={{ height: "9rem" }}>
      <img
        src={moneytranferImg}
        alt="Company Logo"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
        }}
        onError={(e) => {
          e.onerror = null;
          e.target.src = require("../../../assets/no-preview.webp");
        }}
      />
    </Box>
    <Typography variant="body1">No Bank Account available.</Typography>
  </Box>
);
const CustomToolbar = () => {
  return (
    <GridToolbarContainer className="flex justify-end px-2 py-2">
      <div className="flex gap-2">
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </div>
    </GridToolbarContainer>
  );
};

const BankTransaction = () => {
  const { showDialog } = useDialog();
  const dispatch = useDispatch();
  const [anchorDetail, setAnchorDetail] = useState(null);
  const { id: accountId, accountName } = useParams();
  const decodedBankName = decodeURIComponent(accountName).replace(/-/g, " ");
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [deleteId, setDeleteID] = useState(null);
  const [openDeleteTransaction, setOpenDeleteTransaction] = useState(false);
  const { token } = useSelector((data) => data.user);
  const { bankDetail, allStatement, loading } = useSelector(
    (state) => state.account
  );

  useEffect(() => {
    dispatch(
      getAllStatement({
        url: `${backend_api}bank/transactions/?account=${accountId}`,
        token: token,
      })
    );
    dispatch(
      getBankDetail({
        url: `${backend_api}bank/accounts/${accountId}/`,
        token: token,
      })
    );
  }, [dispatch, token, accountId]);

  const handleDelete = (id) => {
    setDeleteID(id);
    setOpenDeleteTransaction(true);
  };
  const handleDeleteTransaction = async (id) => {
    try {
      setDeleteLoad(true);
      const response = await axios.delete(
        `${backend_api}bank/transactions/${deleteId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response?.status === 204) {
        setDeleteLoad(false);
        setOpenDeleteTransaction(false);
        toast.success("Transaction deleted successfully");
        dispatch(
          getAllStatement({
            url: `${backend_api}bank/transactions/?account=${accountId}`,
            token: token,
          })
        );
      }
    } catch (error) {
      toast.error("Failed delete transaction");
    } finally {
      setDeleteLoad(false);
    }
  };
  const handleEdit = (id) => {
    showDialog(
      <NewTransactionForm
        accountId={accountId}
        TransactionId={id}
        accountType={bankDetail?.account_type}
        editMode={true}
      />,
      {
        title: "Edit Transaction",
      }
    );
  };

  const rows = useMemo(() => {
    if (!Array.isArray(allStatement)) {
      console.error("allStatement is not an array:", allStatement);
      return [];
    }
    return allStatement?.map((item) => ({
      id: item?.id,
      date: item?.date || "-",
      source: item?.source_account
        ? item?.source_account?.account_name
        : item?.source_destination ?? "-",
      reference: item?.check_reference_no || "-",
      payer_payee: item?.payer_payee || "-",
      client: item?.client
        ? `${item.client.first_name} ${item.client.last_name}`
        : "-",
      matter: item?.matter
        ? generateUniqueMatterName(item?.matter?.id, item?.matter?.case_name)
        : "-",
      fund_out: item?.transaction_type === "withdrawal" ? item?.amount : "-",
      fund_in: item?.transaction_type === "deposit" ? item?.amount : "-",
      status: item?.status || "pending",
      running_balance: item?.balance_amount || "-",
      description: item?.description || "-",
    }));
  }, [allStatement]);

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      sortable: false,
      renderCell: (item) => (
        <Box sx={{ display: "flex", gap: "0.2rem" }}>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleEdit(item.id)}
            >
              <DriveFileRenameOutlineRoundedIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              size="small"
              onClick={() => handleDelete(item.id)}
            >
              <DeleteRoundedIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    { field: "date", headerName: "Date", width: 150 },
    { field: "source", headerName: "Source/Destination", width: 200 },
    { field: "reference", headerName: "Reference", width: 150 },
    { field: "payer_payee", headerName: "Payer/Payee", width: 150 },
    { field: "client", headerName: "Client", width: 150 },
    { field: "matter", headerName: "Matter", width: 120 },
    { field: "description", headerName: "Description", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (item) => (
        <Chip
          size="small"
          label={item.value}
          color={item.value === "completed" ? "success" : "warning"}
          variant="outlined"
        />
      ),
    },
    { field: "fund_out", headerName: "Fund Out ($)", width: 120 },
    { field: "fund_in", headerName: "Fund In ($)", width: 120 },

    {
      field: "running_balance",
      headerName: "Balance ($)",
      width: 150,
      renderCell: (item) => (
        <Typography variant="body2" color="text.secondary">
          {item.value}
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div className="flex justify-between bg-white rounded-lg items-center shadow-md p-2">
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <h2 className={`${styles.title}`}>
                Transactions of {decodedBankName}
              </h2>
              <IconButton onClick={(e) => setAnchorDetail(e.currentTarget)}>
                <KeyboardArrowDownRoundedIcon />
              </IconButton>
              <Menu
                anchorEl={anchorDetail}
                open={Boolean(anchorDetail)}
                onClose={() => setAnchorDetail(null)}
              >
                <Box
                  sx={{
                    width: "25rem",
                    px: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.3rem",
                  }}
                >
                  {/* Header */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1rem", fontWeight: "500" }}
                    >
                      Bank Details
                    </Typography>

                    {bankDetail?.is_default && (
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "center",
                          fontSize: "0.8rem",
                          width: "4rem",
                          fontWeight: 500,
                          px: "0.2rem",
                          borderRadius: "1rem",
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #4CAF50",
                          color: "#4CAF50",
                        }}
                      >
                        Default
                      </Typography>
                    )}
                  </Box>

                  <Divider />

                  {/* Show Important Bank Details */}
                  {bankDetail && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Account Holder
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.account_holder_name || "N/A"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Bank Name
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.account_name || "N/A"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Account Number
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.account_number || "N/A"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Account Type
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.account_type || "N/A"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Balance
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.balance
                            ? `$${bankDetail?.balance}`
                            : "N/A"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Currency
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.currency || "N/A"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Swift Code
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.swift_code || "N/A"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem",
                            fontWeight: 500,
                            color: "text.secondary",
                          }}
                        >
                          Transit/Routing Number
                        </Typography>
                        <Typography sx={{ fontSize: "0.9rem" }}>
                          {bankDetail?.transit_routing_number || "N/A"}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              </Menu>
            </Box>
            <Box className="flex gap-4">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  showDialog(
                    <TranferFundFrom
                      accountId={accountId}
                      accountType={bankDetail?.account_type}
                    />,
                    {
                      title: "Transfer Fund To",
                    }
                  );
                }}
              >
                Transfer Funds
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  showDialog(
                    <NewTransactionForm
                      accountId={accountId}
                      accountType={bankDetail?.account_type}
                      {...(bankDetail?.account_type === "client fund"
                        ? { ClientAccountId: bankDetail?.client }
                        : {})}
                    />,
                    {
                      title: " New transaction",
                    }
                  );
                }}
              >
                New Transaction
              </Button>
            </Box>
          </div>
          <div className="flex items-start justify-between w-full">
            <div className="w-full">
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Box
                  className="bg-white rounded-lg justify-between h-[80vh] p-2 w-full shadow-lg"
                  sx={{ ...pageScrollStyle, overflow: "auto" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "0.8rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1.2rem",
                            textTransform: "capitalize",
                          }}
                        >
                          {bankDetail?.client?.first_name
                            ? `${bankDetail?.client?.first_name} ${bankDetail?.client?.last_name}`
                            : null}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "0.8rem",
                            color: "text.secondary",
                          }}
                        >
                          {bankDetail?.client?.first_name?.reminder_mail
                            ? bankDetail?.client?.first_name?.reminder_mail
                            : null}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", gap: "1rem" }}>
                        <Box>
                          <TextField
                            size="small"
                            placeholder="Search"
                            className="flex-1"
                            label="Search"
                            sx={{ width: "15rem" }}
                          />
                        </Box>
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<FilterListRoundedIcon />}
                        >
                          Filter
                        </Button>
                      </Box>
                    </Box>

                    <Box>
                      <DataTable
                        columns={columns}
                        rows={rows}
                        CustomToolbar={CustomToolbar}
                        CustomNoRowsOverlay={CustomNoRowsOverlay}
                        loading={loading}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </Box>
        {/* ----------------- DELETE STATEMENT POP UP HERE ----------------- */}
        <Dialog
          open={openDeleteTransaction}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenDeleteTransaction(false)}
        >
          <DialogTitle>Sure you want to delete this transaction</DialogTitle>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setOpenDeleteTransaction(false)}
            >
              Cancle
            </Button>

            <LoadingButton
              loading={deleteLoad}
              size="small"
              variant="contained"
              color="error"
              onClick={() => handleDeleteTransaction(deleteId)}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default BankTransaction;
