import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const createTask = createAsyncThunk(
  "createTask",
  async ({ url, body, token }) => {
    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      throw error?.response?.data || error?.message;
    }
  }
);

export const createTaskList = createAsyncThunk(
  "createTaskList",
  async ({ url, body, token }) => {
    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response?.data;
    } catch (error) {
      throw error?.response?.data || error?.message;
    }
  }
);

export const createTaskType = createAsyncThunk(
  "createTaskType",
  async ({ url, body, token }) => {
    try {
      const response = await axios.post(url, body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to create Task type");
      throw error.response?.data || error.message;
    }
  }
);

export const getAllTaskType = createAsyncThunk(
  "getAllTaskType",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to get task type");
      throw error.response?.data || error.message;
    }
  }
);
export const getAllTask = createAsyncThunk(
  "getAllTask",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to get taks");
      throw error.response?.data || error.message;
    }
  }
);
export const getAllTaskList = createAsyncThunk(
  "getAllTaskList",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to get taks list");
      throw error.response?.data || error.message;
    }
  }
);

export const updateTaskOrType = createAsyncThunk(
  "updateTaskOrType",
  async ({ url, body, token }) => {
    try {
      const response = await axios.patch(url, body, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);
export const updateTaskList = createAsyncThunk(
  "task/updateTaskList",
  async ({ url, body, token }, { rejectWithValue }) => {
    try {
      const response = await axios.put(url, body, {
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const TaskSlice = createSlice({
  name: "TaskSlice",
  initialState: {
    allTask: null,
    allTaskType: null,
    allTaskList: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTask.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create task type";
      })
      .addCase(createTaskType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTaskType.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createTaskType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create task type";
      })
      .addCase(getAllTaskType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTaskType.fulfilled, (state, action) => {
        state.loading = false;
        state.allTaskType = action?.payload;
      })
      .addCase(getAllTaskType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create task type";
      })
      .addCase(getAllTask.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTask.fulfilled, (state, action) => {
        state.loading = false;
        state.allTask = action?.payload;
      })
      .addCase(getAllTask.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create task type";
      })
      .addCase(updateTaskOrType.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTaskOrType.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTaskOrType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to edit task type";
      })
      .addCase(updateTaskList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTaskList.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTaskList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to edit task list";
      })
      .addCase(createTaskList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTaskList.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createTaskList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create task list";
      })
      .addCase(getAllTaskList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllTaskList.fulfilled, (state, action) => {
        state.loading = false;
        state.allTaskList = action?.payload;
      })
      .addCase(getAllTaskList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create task list";
      });
  },
});

export const TaskSliceReducer = TaskSlice.reducer;
