import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to get users data");
      throw error.response?.data || error.message;
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    allUsers: null,
    loading: false,
    error: null,
    token: null,
    user: null,
    lawerId: null,
    userId: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLawerId: (state, action) => {
      state.lawerId = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
      state.lawerId = null;
      state.userId = null;
      state.allUsers = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.allUsers = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setUser, setToken, setLawerId, setUserId, logout } =
  userSlice.actions;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["token", "userId", "lawerId"],
};

export const persistedReducer = persistReducer(
  persistConfig,
  userSlice.reducer
);
