import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import noOption from "../../../../assets/no-option-found.webp";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import {
  currencyList,
  ListboxComponent,
  NoDataOptionField,
} from "../../../../static/staticdata";
import { useDispatch, useSelector } from "react-redux";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import {
  getCases,
  getPracticeArea,
} from "../../../../redux/Apps/Lawyerapp/CaseSlice/CaseSlice";
import { useDialog } from "../../../DialogContext";
import { backend_api } from "../../../../static/server";
import { getAllFilterContacts } from "../../../../redux/Apps/Lawyerapp/ContactSlice/ContactSlice";
import { getAllCustomField } from "../../../../redux/Apps/Lawyerapp/CustomFieldSlice";
import { LoadingButton } from "@mui/lab";
import {
  getAllAccounts,
  getAllStatement,
  UpdateTransaction,
} from "../../../../redux/Apps/Lawyerapp/AccountSlice/AccountSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { generateUniqueMatterName } from "../../../../styles/styles";

export const renderContactOption = (props, option) => (
  <li
    {...props}
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <Typography variant="body1">
      {option?.first_name
        ? `${option?.first_name} ${option?.last_name}`
        : option?.company_name
        ? option?.company_name
        : ""}
    </Typography>
    <Typography variant="body1">
      {option?.contact_type === "person" ? (
        <AccountCircleRoundedIcon
          sx={{ color: "#bf3fe1", fontSize: "0.9rem" }}
        />
      ) : (
        <BusinessRoundedIcon sx={{ color: "#2e77f5", fontSize: "0.9rem" }} />
      )}
    </Typography>
  </li>
);

const NewTransactionForm = ({
  accountId,
  accountType,
  editMode,
  TransactionId,
  ClientAccountId,
}) => {
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(editMode || false);
  const { token } = useSelector((data) => data.user);
  const {
    filteredContacts,
    allPracticeArea,
    allCustomField,
    allTaskList,
    editcaseTemplateData,
  } = useSelector((state) => ({
    filteredContacts: state.contact.filteredContacts,
    allPracticeArea: state.case.allPracticeArea,
    allCustomField: state.customField.allCustomField,
    allTaskList: state.task.allTaskList,
    editcaseTemplateData: state.case.editcaseTemplateData,
  }));
  const { allCase, loading: caseLoading } = useSelector((state) => state.case);
  const { AllAccounts } = useSelector((state) => state.account);
  const { allStatement } = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(
      getCases({
        url: `${backend_api}case/register/?case_related_person=${ClientAccountId}`,
        token: token,
      })
    );
    dispatch(
      getAllAccounts({ url: `${backend_api}bank/accounts/`, token: token })
    );
    dispatch(
      getPracticeArea({
        url: `${backend_api}case/case-type/?case_service_type=practice_area`,
        token: token,
      })
    );
    dispatch(
      getAllFilterContacts({
        url: `${backend_api}contact/retrieve/${ClientAccountId}/`,
        token: token,
      })
    );
  }, [dispatch, token, ClientAccountId]);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      account: "",
      amount: "",
      date: "",
      source_destination: "",
      payer_payee: "",
      currency: "",
      exchange_rate: "",
      transaction_type: "",
      check_reference_no: "",
      status: "",
      description: "",
    },
  });

  // edit more use two use effect because it set the value of input after the dialog opens for reduse the time of set value in inputs
  useEffect(() => {
    if (isEditMode && editMode) {
      dispatch(
        getAllStatement({
          url: `${backend_api}bank/transactions/${TransactionId}/`,
          token: token,
        })
      );
    }
  }, [isEditMode, editMode, TransactionId, dispatch, token]);
  useEffect(() => {
    if (
      isEditMode &&
      editMode &&
      allStatement &&
      Object.keys(allStatement).length > 0
    ) {
      Object.keys(allStatement).forEach((key) => {
        setValue(key, allStatement[key], { shouldDirty: true });
      });
    }
  }, [isEditMode, editMode, allStatement, setValue]);

  useEffect(() => {
    if (!AllAccounts || !accountId) return;
    const selectedAccount =
      AllAccounts.find((acc) => acc.id === Number(accountId)) || null;
    setValue("account", selectedAccount?.id);
    const today = dayjs().format("YYYY-MM-DD");
    setValue("date", today, { shouldValidate: true });
  }, [accountId, AllAccounts, setValue]);

  const onSubmit = async (data) => {
    try {
      let response;
      if (isEditMode) {
        response = await dispatch(
          UpdateTransaction({
            url: `${backend_api}bank/transactions/${TransactionId}/`,
            body: data,
            token: token,
          })
        );
        toast.success("Transaction updated successfully ");
      } else {
        response = await axios.post(`${backend_api}bank/transactions/`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });
        toast.success("Transaction recorded successfully ");
      }

      reset();
      closeDialog();
      dispatch(
        getAllStatement({
          url: `${backend_api}bank/transactions/?account=${accountId}`,
          token: token,
        })
      );
      return response;
    } catch (error) {
      toast.error(
        isEditMode
          ? "Failed to update transaction "
          : "Failed to record transaction "
      );
    }
  };

  const handleMatterNewTab = () => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/dashboard/matter`;
    const newTab = window.open(newUrl, "_blank");
    if (newTab) {
      newTab.onload = () => {
        newTab.sessionStorage.setItem("dialogCase", "true");
      };
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              p: "1rem",
            }}
          >
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="transaction_type"
                control={control}
                rules={{ required: "Transaction type is required" }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl size="small" fullWidth error={!!error}>
                    <InputLabel
                      id="transaction_type-label"
                      sx={{ bgcolor: "#fff", px: "0.3rem" }}
                    >
                      <span>
                        Transaction Type
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    </InputLabel>
                    <Select {...field} labelId="transaction_type-label">
                      <MenuItem value="deposit">Deposit</MenuItem>
                      <MenuItem value="withdrawal">Withdrawal</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
              <Controller
                name="currency"
                control={control}
                rules={{ required: "Currency is required" }}
                render={({ field }) => (
                  <FormControl size="small" fullWidth>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={currencyList || []}
                      getOptionLabel={(option) => option?.label || ""}
                      value={
                        currencyList?.find(
                          (option) => option?.value === field?.value
                        ) || null
                      }
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue?.value : null)
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.value === value?.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Currency<span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          size="small"
                          fullWidth
                          error={errors?.currency}
                          helperText={errors?.currency?.message}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="amount"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label={
                      <span>
                        Amount<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    error={errors?.amount}
                    helperText={errors?.amount?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyRoundedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="exchange_rate" //this input use for the set the exchange rate of two diff currency rate inr to usd
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Exchange Rate"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Date is required" }}
                defaultValue={dayjs().format("YYYY-MM-DD")}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(newValue) => {
                      if (newValue && dayjs(newValue).isValid()) {
                        field.onChange(dayjs(newValue).format("YYYY-MM-DD"));
                      } else {
                        field.onChange(null);
                      }
                    }}
                    sx={{ width: "100%" }}
                    slotProps={{
                      textField: {
                        label: (
                          <span>
                            Date <span style={{ color: "red" }}>*</span>
                          </span>
                        ),
                        size: "small",
                        variant: "outlined",
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                    format="MM/DD/YYYY"
                  />
                )}
              />
              <Controller
                name="account"
                control={control}
                rules={{ required: "This field is required" }}
                render={({ field }) => (
                  <FormControl fullWidth error={errors?.account}>
                    <Autocomplete
                      disablePortal
                      options={AllAccounts || []}
                      getOptionLabel={(option) => option.account_name}
                      value={
                        AllAccounts?.find((acc) => acc.id === field.value) ||
                        null
                      } // Fix here
                      onChange={(_, newValue) =>
                        field.onChange(newValue ? newValue.id : null)
                      }
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          key={option.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            {option.account_name}
                          </Typography>
                          <Box>
                            {option.is_default && (
                              <Chip
                                label="Default"
                                size="small"
                                color="primary"
                              />
                            )}
                          </Box>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Account<span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          size="small"
                          error={!!errors?.account}
                          helperText={errors?.account?.message}
                        />
                      )}
                    />
                  </FormControl>
                )}
              />
            </Box>

            {accountType === "client fund" && (
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
              >
                <Controller
                  name="client"
                  control={control}
                  render={({ field }) => {
                    const fixedClientId = ClientAccountId;
                    if (!field.value && fixedClientId) {
                      field.onChange(fixedClientId);
                    }
                    const selectedClient = filteredContacts?.data;
                    return (
                      <FormControl fullWidth>
                        <TextField
                          label="Client"
                          size="small"
                          value={
                            selectedClient
                              ? selectedClient?.first_name
                                ? `${selectedClient?.first_name} ${selectedClient?.last_name}`
                                : selectedClient?.company_name || ""
                              : ""
                          }
                          disabled={true}
                          InputProps={{
                            startAdornment: selectedClient ? (
                              <Chip
                                size="small"
                                sx={{
                                  mr: "0.5rem",
                                  bgcolor:
                                    selectedClient?.contact_type === "person"
                                      ? "#bf3fe1"
                                      : "#2e77f5",
                                  color: "#fff",
                                }}
                                label={
                                  <Typography variant="body1">
                                    {selectedClient?.contact_type ===
                                    "person" ? (
                                      <AccountCircleRoundedIcon
                                        sx={{
                                          color: "#fff",
                                          fontSize: "0.9rem",
                                        }}
                                      />
                                    ) : (
                                      <BusinessRoundedIcon
                                        sx={{
                                          color: "#fff",
                                          fontSize: "0.9rem",
                                        }}
                                      />
                                    )}
                                  </Typography>
                                }
                              />
                            ) : null,
                          }}
                        />
                        {errors?.account && (
                          <FormHelperText>
                            {errors?.account?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                />
                <Controller
                  name="matter"
                  control={control}
                  render={({ field }) => (
                    <FormControl size="small" fullWidth error={errors?.case}>
                      <Autocomplete
                        {...field}
                        options={allCase || []}
                        getOptionLabel={(option) => {
                          if (option?.id) {
                            return generateUniqueMatterName(
                              option?.id,
                              option?.case_name
                            );
                          }
                        }}
                        value={
                          field.value
                            ? allCase?.find((item) => item.id === field.value)
                            : null
                        }
                        onChange={(_, newValue) =>
                          field.onChange(newValue?.id || null)
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Matter" size="small" />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <Typography>
                                {option.id
                                  ? `000${option.id}-${option.case_name}`
                                  : ""}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  p: "0.2rem 0.5rem",
                                  borderRadius: "1rem",
                                  textAlign: "center",
                                  fontSize: "0.7rem",
                                  bgcolor:
                                    option?.case_status === "open"
                                      ? "#4CAF50"
                                      : option?.case_status === "pending"
                                      ? "#FFC107"
                                      : "#F44336",
                                  color: "#fff",
                                }}
                              >
                                {option?.case_status}
                              </Typography>
                            </Box>
                          </li>
                        )}
                        ListboxComponent={(props) => (
                          <ListboxComponent
                            {...props}
                            btnIcon={<ArrowOutwardRoundedIcon />}
                            btnText="Add Matter"
                            handleClikListBtn={handleMatterNewTab}
                          />
                        )}
                        noOptionsText={
                          <NoDataOptionField
                            imgSrc={noOption}
                            text="No matters found"
                            btnText="Matter"
                            handleClickNoData={() =>
                              navigate("/dashboard/matter")
                            }
                          />
                        }
                      />
                    </FormControl>
                  )}
                />
              </Box>
            )}

            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="source_destination"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Source/Destination"
                    size="small"
                    fullWidth
                  />
                )}
              />
              <Controller
                name="payer_payee"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Payer/Payee"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="status"
                control={control}
                rules={{ required: "Status is required" }}
                render={({ field }) => (
                  <FormControl size="small" fullWidth error={errors?.status}>
                    <InputLabel
                      id="status-label"
                      sx={{ bgcolor: "#fff", px: "0.3rem" }}
                    >
                      <span>
                        Status<span style={{ color: "red" }}>*</span>
                      </span>
                    </InputLabel>
                    <Select
                      {...field}
                      labelId="status-label"
                      defaultValue="pending"
                    >
                      <MenuItem value={"pending"}>Pending</MenuItem>
                      <MenuItem value={"completed"}>Completed</MenuItem>
                    </Select>
                    {errors?.between_to && (
                      <FormHelperText>
                        {errors.between_to.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name="check_reference_no"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Check or reference no."
                    size="small"
                  />
                )}
              />
            </Box>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={1.5}
                  fullWidth
                  label="Description"
                />
              )}
            />
          </Box>
          <Box
            sx={{
              height: "fit-content",
              borderTop: "1px solid #ddd",
              position: "sticky",
              bottom: 0,
              backdropFilter: "blur(10px)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: "0.5rem",
              zIndex: 1000,
              gap: "0.5rem",
            }}
          >
            <Button variant="outlined" onClick={() => closeDialog()}>
              Cancel
            </Button>
            <LoadingButton
              //   loading={submitLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              {isEditMode ? "Edit Transaction" : "Recode Transaction"}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default NewTransactionForm;
