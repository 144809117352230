import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  Autocomplete,
  Typography,
} from "@mui/material/";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getCases } from "../../../redux/Apps/Lawyerapp/CaseSlice/CaseSlice";
import { backend_api } from "../../../static/server";
import {
  ListboxComponent,
  NoDataOptionField,
} from "../../../static/staticdata";
import ArrowOutwardRoundedIcon from "@mui/icons-material/ArrowOutwardRounded";
import noOption from "../../../assets/no-option-found.webp";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { pageScrollStyle } from "../../../styles/styles";
import { toast } from "react-toastify";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { useDialog } from "../../DialogContext";
import {
  getActivities,
  updateActivity,
} from "../../../redux/Apps/Lawyerapp/ActivitySlice/ActivitySlice";

const ServiceTimeForm = ({ timeIn, timeOut, entryData, editMode }) => {
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((data) => data.user);
  const { allCase, loading } = useSelector((state) => state.case);
  const [totalHours, setTotalHours] = useState(0);
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [totalAmount, setTotalAmount] = useState("0.00");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  const [isEditMode, setIsEditMode] = useState(editMode ? editMode : false);

  useEffect(() => {
    dispatch(getCases({ url: `${backend_api}case/register/`, token: token }));
  }, [dispatch, token]);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: "time",
      case: null,
      time_in: null,
      time_out: null,
      hourly_rate: "",
      description: "",
      non_billable: false,
      bill_entry_show: false,
    },
  });

  const time_in = watch("time_in");
  const time_out = watch("time_out");
  const hourly_rate = watch("hourly_rate");
  const nonBillable = watch("non_billable");
  const selectedCase = watch("case");

  useEffect(() => {
    if (entryData && isEditMode && editMode) {
      Object.keys(entryData)?.forEach((key) => {
        setValue(key, entryData[key], { shouldDirty: true });
      });
    }
  }, [entryData, isEditMode, setValue, editMode]);

  useEffect(() => {
    if (timeIn && timeOut) {
      setValue("time_in", timeIn);
      setValue("time_out", timeOut);
    }
  }, [timeIn, timeOut, setValue]);

  useEffect(() => {
    if (time_in && time_out) {
      const startTime = dayjs(time_in);
      const endTime = dayjs(time_out);

      if (startTime.isValid() && endTime.isValid()) {
        const startTimeMs = startTime.valueOf();
        const endTimeMs = endTime.valueOf();
        if (endTimeMs > startTimeMs) {
          const diffInMs = endTimeMs - startTimeMs;
          const diffInSeconds = Math.floor(diffInMs / 1000);

          const hours = Math.floor(diffInSeconds / 3600);
          const minutes = Math.floor((diffInSeconds % 3600) / 60);
          const seconds = diffInSeconds % 60;

          const formattedElapsedTime = `${String(hours).padStart(
            2,
            "0"
          )}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
            2,
            "0"
          )}`;

          setElapsedTime(formattedElapsedTime);
          setTotalSeconds(diffInSeconds);
          setTotalHours(diffInSeconds / 3600);

          if (hourly_rate && !nonBillable) {
            const amount = (
              (diffInSeconds / 3600) *
              parseFloat(hourly_rate)
            ).toFixed(2);
            setTotalAmount(amount);
          } else if (nonBillable) {
            setTotalAmount("0.00");
          }
        } else {
          setElapsedTime("00:00:00");
          setTotalSeconds(0);
          setTotalHours(0);
          setTotalAmount("0.00");
        }
      }
    } else {
      setElapsedTime("00:00:00");
      setTotalSeconds(0);
      setTotalHours(0);
      setTotalAmount("0.00");
    }
  }, [time_in, time_out, hourly_rate, nonBillable]);

  const calculateTotalAmount = (seconds, rate) => {
    if (!seconds || !rate) return "0.00";
    const hours = seconds / 3600;

    return (hours * parseFloat(rate)).toFixed(2);
  };

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    try {
      if (isEditMode) {
        await dispatch(
          updateActivity({
            url: `${backend_api}firm-case/expense/${entryData?.id}/`,
            data: data,
            token: token,
          })
        );
        toast.success("Work session updated successfully");
        setIsEditMode(false);
        closeDialog();
      } else {
        const response = await axios.post(
          `${backend_api}firm-case/expense/`,
          data,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        );
        toast.success("Work session created successfully");
        closeDialog();
        return response;
      }

      reset();
      dispatch(
        getActivities({
          url: `${backend_api}firm-case/expense/`,
          token: token,
        })
      );
    } catch (error) {
      toast.error("Failed to save entry");
    } finally {
      setSubmitLoading(false);
      closeDialog();
      dispatch(
        getActivities({ url: `${backend_api}firm-case/expense/`, token: token })
      );
    }
  };

  const handleMatterNewTab = () => {
    const baseUrl = window.location.origin;
    const newUrl = `${baseUrl}/dashboard/matter`;

    const newTab = window.open(newUrl, "_blank");

    if (newTab) {
      newTab.onload = () => {
        newTab.sessionStorage.setItem("dialogCase", "true");
      };
    }
  };

  const timeFormat = "MM/DD/YYYY HH:mm:ss";

  const isFormValid =
    !!selectedCase &&
    !!time_in &&
    !!time_out &&
    !!hourly_rate &&
    totalSeconds > 0;

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              p: "1rem",
            }}
          >
            <Box>
              <Controller
                name="case"
                control={control}
                rules={{ required: "Matter is required" }}
                render={({ field }) => (
                  <FormControl size="small" fullWidth error={!!errors?.case}>
                    <Autocomplete
                      {...field}
                      options={allCase || []}
                      loading={loading}
                      getOptionLabel={(option) => {
                        if (!option) return "";
                        const caseId = option.id ? `000${option.id}` : "";
                        const caseName = option.case_name || "";
                        return caseId && caseName
                          ? `${caseId}-${caseName}`
                          : "";
                      }}
                      value={
                        field.value
                          ? allCase?.find((item) => item.id === field.value)
                          : null
                      }
                      onChange={(_, newValue) =>
                        field?.onChange(newValue?.id || null)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            <span>
                              Matter<span style={{ color: "red" }}>*</span>
                            </span>
                          }
                          size="small"
                          error={!!errors?.case}
                          helperText={errors?.case?.message}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {option.id
                                ? `000${option.id}-${option.case_name}`
                                : ""}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                p: "0.2rem 0.5rem",
                                borderRadius: "1rem",
                                textAlign: "center",
                                fontSize: "0.7rem",
                                bgcolor:
                                  option?.case_status === "open"
                                    ? "#4CAF50"
                                    : option?.case_status === "pending"
                                    ? "#FFC107"
                                    : "#F44336",
                                color: "#fff",
                              }}
                            >
                              {option?.case_status}
                            </Typography>
                          </Box>
                        </li>
                      )}
                      ListboxComponent={(props) => (
                        <ListboxComponent
                          {...props}
                          btnIcon={<ArrowOutwardRoundedIcon />}
                          btnText="Add Matter"
                          handleClikListBtn={handleMatterNewTab}
                        />
                      )}
                      noOptionsText={
                        <NoDataOptionField
                          imgSrc={noOption}
                          text="No matters found"
                          btnText="Matter"
                          handleClickNoData={() =>
                            navigate("/dashboard/matter")
                          }
                        />
                      }
                    />
                  </FormControl>
                )}
              />
            </Box>

            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="time_in"
                control={control}
                rules={{ required: "Time in is required" }}
                render={({ field }) => (
                  <DateTimePicker
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(newValue) => {
                      const isValidDate = newValue && dayjs(newValue).isValid();
                      field.onChange(
                        isValidDate ? dayjs(newValue).toISOString() : null
                      );
                    }}
                    slotProps={{
                      textField: {
                        label: (
                          <span>
                            Time In<span style={{ color: "red" }}>*</span>
                          </span>
                        ),
                        size: "small",
                        variant: "outlined",
                        fullWidth: true,
                        error: !!errors?.time_in,
                        helperText: errors?.time_in?.message,
                      },
                      popper: {
                        sx: {
                          "& .MuiPickersPopper-root": {
                            maxWidth: "100%",
                            maxHeight: "100%",
                            overflow: "hidden",
                          },
                          "& .MuiPaper-root": {
                            width: "fit-content",
                            maxHeight: "15rem",
                            overflowY: "auto",
                            ...pageScrollStyle,
                          },
                          "& .MuiList-root": {
                            ...pageScrollStyle,
                          },
                          "& .MuiMenuItem-root": {
                            borderRadius: "0.5rem",
                          },
                        },
                      },
                    }}
                    format={timeFormat}
                    maxDate={dayjs()}
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                    ampm={false}
                  />
                )}
              />
              <Controller
                name="time_out"
                control={control}
                rules={{ required: "Time out is required" }}
                render={({ field }) => (
                  <DateTimePicker
                    value={field.value ? dayjs(field.value) : null}
                    onChange={(newValue) => {
                      const isValidDate = newValue && dayjs(newValue).isValid();
                      field.onChange(
                        isValidDate ? dayjs(newValue).toISOString() : null
                      );
                    }}
                    slotProps={{
                      textField: {
                        label: (
                          <span>
                            Time Out<span style={{ color: "red" }}>*</span>
                          </span>
                        ),
                        size: "small",
                        variant: "outlined",
                        fullWidth: true,
                        error: !!errors?.time_out,
                        helperText: errors?.time_out?.message,
                      },
                      popper: {
                        sx: {
                          "& .MuiPickersPopper-root": {
                            maxWidth: "100%",
                            maxHeight: "100%",
                            overflow: "hidden",
                          },
                          "& .MuiPaper-root": {
                            width: "fit-content",
                            maxHeight: "15rem",
                            overflowY: "auto",
                            ...pageScrollStyle,
                          },
                          "& .MuiList-root": {
                            ...pageScrollStyle,
                          },
                          "& .MuiMenuItem-root": {
                            borderRadius: "0.5rem",
                          },
                        },
                      },
                    }}
                    format={timeFormat}
                    minDate={time_in ? dayjs(time_in).startOf("day") : null}
                    disabled={!time_in}
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                    ampm={false}
                  />
                )}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              {/* Time display with HH:MM:SS format */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid  #c5c5c7",
                  borderRadius: "0.2rem",
                  px: "0.5rem",
                  py: "0.5rem",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "0.8rem", fontWeight: "500" }}
                >
                  Total Time (HH:MM:SS)
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "600",
                    fontFamily: "monospace",
                  }}
                >
                  {elapsedTime || "00:00:00"}
                </Typography>
              </Box>

              {/* Standard hours display */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid  #c5c5c7",
                  borderRadius: "0.2rem",
                  px: "0.5rem",
                  py: "0.5rem",
                  width: "100%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "0.8rem", fontWeight: "500" }}
                >
                  Total Hours (Decimal)
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "0.9rem", fontWeight: "600" }}
                >
                  {totalHours.toFixed(4)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="hourly_rate"
                control={control}
                defaultValue=""
                rules={{ required: "Hourly rate is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    label={
                      <span>
                        Hourly Rate<span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    type="number"
                    fullWidth
                    error={!!errors?.hourly_rate}
                    helperText={errors?.hourly_rate?.message}
                    InputProps={{
                      startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      // Don't calculate amount if non-billable is checked
                      if (!nonBillable && totalSeconds > 0 && e.target.value) {
                        const amount = calculateTotalAmount(
                          totalSeconds,
                          e.target.value
                        );
                        setTotalAmount(amount);
                      }
                    }}
                  />
                )}
              />
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #c5c5c7",
                  borderRadius: "0.2rem",
                  px: "0.5rem",
                  py: "0.5rem",
                  bgcolor: nonBillable ? "#f5f5f5" : "#e3f2fd",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontSize: "0.9rem", fontWeight: "500" }}
                >
                  Total Amount
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: nonBillable ? "#9e9e9e" : "primary.main",
                  }}
                >
                  ${nonBillable ? "0.00" : totalAmount}
                </Typography>
              </Box>
            </Box>

            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{ required: "Description is required" }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  label={
                    <span>
                      Describe your work
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  multiline
                  rows={3}
                  fullWidth
                  error={!!errors?.description}
                  helperText={errors?.description?.message}
                />
              )}
            />
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Controller
                name="non_billable"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        size="small"
                        checked={field.value}
                        onChange={(e) => {
                          const newValue = e.target.checked;
                          field.onChange(newValue);
                          if (newValue) {
                            setTotalAmount("0.00");
                          } else if (hourly_rate && totalSeconds > 0) {
                            const amount = calculateTotalAmount(
                              totalSeconds,
                              hourly_rate
                            );
                            setTotalAmount(amount);
                          }
                        }}
                      />
                    }
                    label="Non-Billable"
                  />
                )}
              />
              <Controller
                name="bill_entry_show"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        size="small"
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                        disabled={!nonBillable}
                      />
                    }
                    label="Show Entry on Bill"
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              height: "fit-content",
              borderTop: "1px solid #ddd",
              position: "sticky",
              bottom: 0,
              backdropFilter: "blur(10px)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: "0.5rem",
              zIndex: 1000,
              gap: "0.5rem",
            }}
          >
            <Button variant="outlined" onClick={() => closeDialog()}>
              Cancel
            </Button>
            <LoadingButton
              loading={submitLoading}
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isFormValid}
            >
              {isEditMode ? "Edit Time" : "Enter Time"}
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ServiceTimeForm;
