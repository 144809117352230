import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { backend_api } from "../../../../static/server";

export const getAllBilles = createAsyncThunk(
  "bille/getAllBilles",
  async ({ url, token }) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error.response?.data || error.message;
    }
  }
);
export const updateStatus = createAsyncThunk(
  "bille/updateStatus",
  async ({ id, data, token }) => {
    try {
      const response = await axios.put(
        `${backend_api}firm-case/bill/${id}/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response);
      toast.success("Status update successfully");
      return response.data;
    } catch (error) {
      toast.error("Failed to update billes");
      throw error.response?.data || error.message;
    }
  }
);

const BillingSlice = createSlice({
  name: "Billing",
  initialState: {
    allBilles: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllBilles.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllBilles.fulfilled, (state, action) => {
        state.allBilles = action?.payload;
        state.loading = false;
      })
      .addCase(getAllBilles.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

export const BillingReducer = BillingSlice.reducer;
