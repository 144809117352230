import { combineReducers } from "redux";
import { persistedReducer } from "./Apps/users/userSlice";
import { DashboardReducer } from "./Apps/Lawyerapp/DashboardSlice/DashboardSlice";
import { CustomFieldReducer } from "./Apps/Lawyerapp/CustomFieldSlice";
import { TaskSliceReducer } from "./Apps/Lawyerapp/TaskSlice/TaskSlice";
import { CaseReducer } from "./Apps/Lawyerapp/CaseSlice/CaseSlice";
import { contactReducer } from "./Apps/Lawyerapp/ContactSlice/ContactSlice";
import { AccountReducer } from "./Apps/Lawyerapp/AccountSlice/AccountSlice";
import { ActivityReducer } from "./Apps/Lawyerapp/ActivitySlice/ActivitySlice";
import { BillingReducer } from "./Apps/Lawyerapp/BillingSlice/BillingSlice";

const rootReducer = combineReducers({
  user: persistedReducer,
  dashboard: DashboardReducer,
  contact: contactReducer,
  customField: CustomFieldReducer,
  task: TaskSliceReducer,
  case: CaseReducer,
  account: AccountReducer,
  activity: ActivityReducer,
  billing: BillingReducer,
});

export default rootReducer;
