import React, { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { backend_api } from "../../../static/server";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../redux/Apps/users/userSlice";
import { LoadingButton } from "@mui/lab";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import { debounce } from "../../../styles/styles";
import { useDialog } from "../../DialogContext";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const InviteUserForm = () => {
  const { closeDialog } = useDialog();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [userExist, setUserExist] = useState(null);

  const { token } = useSelector((data) => data.user);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      reminder_mail: "",
    },
  });

  const handleCheckEmail = useCallback(
    debounce(async (email) => {
      if (!email || !EMAIL_REGEX.test(email)) {
        setUserExist(null);
        return;
      }
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${backend_api}user/check-mail/?email=${email}`,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (response?.data?.exist) {
          setUserExist(response?.data?.exist);
          toast.info("Email already exists");
        }
      } catch (error) {
        console.error("Error checking email:", error);
        toast.error("Error checking email availability");
      } finally {
        setIsLoading(false);
      }
    }, 300),
    [setUserExist, setIsLoading]
  );

  const onSubmit = async (data) => {
    if (!EMAIL_REGEX.test(data.reminder_mail)) {
      toast.error("Invalid email address.");
      return;
    }
    setIsLoading(true);

    try {
      const res = await axios.post(
        `${backend_api}team/create-employee/`,
        data,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );

      if (res.status === 200 || res.status === 201) {
        toast.success("Employee added successfully!");
        reset();
        dispatch(
          getAllUsers({
            url: `${backend_api}team/create-employee/`,
            token: token,
          })
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Something went wrong!");
    }
  };

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              p: "1rem",
            }}
          >
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Name"
                  size="small"
                  fullWidth
                />
              )}
            />
            <Controller
              name="reminder_mail"
              control={control}
              rules={{
                required: "Email is required",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Enter a valid email",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={
                    <span>
                      Email<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  size="small"
                  fullWidth
                  error={!!errors.reminder_mail}
                  helperText={errors.reminder_mail?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    handleCheckEmail(e.target.value);
                  }}
                />
              )}
            />
          </Box>
          <Box
            sx={{
              height: "fit-content",
              borderTop: "1px solid #ddd",
              position: "sticky",
              bottom: 0,
              backdropFilter: "blur(10px)",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              p: "0.5rem",
              zIndex: 1000,
              gap: "0.5rem",
            }}
          >
            <Button
              size="small"
              variant="outlined"
              onClick={() => closeDialog()}
            >
              Cancel
            </Button>
            <LoadingButton
              size="small"
              variant="contained"
              type="submit"
              color="primary"
              startIcon={<GroupAddRoundedIcon />}
              loading={isLoading}
              disabled={userExist}
            >
              Send Invite
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default InviteUserForm;
