import React from "react";
import { Avatar } from "@mui/material";
import { backend_url } from "../static/server";

const colors = {
  A: "#F87171",
  B: "#60A5FA",
  C: "#34D399",
  D: "#FBBF24",
  E: "#A78BFA",
  F: "#F97316",
  G: "#4ADE80",
  H: "#93C5FD",
  I: "#FACC15",
  J: "#C084FC",
  K: "#FB7185",
  L: "#38BDF8",
  M: "#22C55E",
  N: "#F59E0B",
  O: "#A3E635",
  P: "#818CF8",
  Q: "#EC4899",
  R: "#2DD4BF",
  S: "#F43F5E",
  T: "#06B6D4",
  U: "#F472B6",
  V: "#EAB308",
  W: "#D8B4FE",
  X: "#A1A1AA",
  Y: "#64748B",
  Z: "#78716C",
};

const getColorForLetter = (letter) => {
  const uppercaseLetter = letter?.toUpperCase();
  return colors[uppercaseLetter] || "#94A3B8"; // Default color if letter isn't mapped
};

const UserAvatar = ({ firstName, imgSrc, height, width }) => {
  const firstLetter = firstName?.[0] || "";
  const bgColor = getColorForLetter(firstLetter);

  return (
    <Avatar
      alt={firstName || "User"}
      src={imgSrc ? `${backend_url}${imgSrc}` : ""}
      sx={{
        width: height,
        height: height,
        backgroundColor: imgSrc ? "transparent" : bgColor,
        color: "white",
      }}
    >
      {!imgSrc && firstLetter}
    </Avatar>
  );
};

export default UserAvatar;
