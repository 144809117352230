import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  Paper,
  Container,
} from "@mui/material";
import {
  CloseRounded,
  EmailOutlined,
  LockOutlined,
  CheckCircleOutlined,
} from "@mui/icons-material";
import emailSend from "../../../assets/email_sent.webp";
import { backend_api } from "../../../static/server";
import RetroGridBg from "../../../components/RetroGridBg";
import Navbar from "../../../components/LawyerCompo/Dashboard/Navbar";

const NewUserPage = () => {
  const [searchParams] = useSearchParams();
  const urlMail = searchParams.get("mail");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openNewUserForgotPass, setOpenNewUserForgotPass] = useState(true);

  useEffect(() => {
    if (urlMail) {
      handleForgotPasswordLink(urlMail);
    }
  }, [urlMail]);

  const handleForgotPasswordLink = async (email) => {
    try {
      const data = { email: email };
      const response = await axios.get(
        `${backend_api}user/forget-password/?send_reset_link&email=${email}`,
        data
      );
      setIsEmailSent(true);
    } catch (error) {
      console.error("Forgot Password Error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseForgotPass = () => {
    setOpenNewUserForgotPass(false);
  };

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <RetroGridBg />
      </Box>

      <Navbar />

      {/* Main Content */}
      <Container
        maxWidth="lg"
        sx={{ position: "relative", zIndex: 1, p: "1rem" }}
      >
        <Box>
          {/* Welcome Section */}
          <Paper
            elevation={3}
            sx={{
              p: 2,
              mb: 2,
              borderRadius: 2,
              backgroundColor: "background.paper",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h5"
              color="primary"
              sx={{ mb: 2, fontWeight: 600 }}
            >
              Welcome to Our Platform!
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              We're excited to have you join us. Let's get your account set up
              and ready to go.
            </Typography>
          </Paper>

          {/* Steps Section */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                p: 3,
                borderRadius: 2,
                flex: "1 1 250px",
                maxWidth: "350px",
                backgroundColor: "background.paper",
              }}
            >
              <EmailOutlined
                sx={{ fontSize: 40, color: "primary.main", mb: 2 }}
              />
              <Typography variant="h6" sx={{ mb: 2 }}>
                1. Check Your Email
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We've sent a password reset link to your email address. Please
                check your inbox and spam folder.
              </Typography>
            </Paper>

            <Paper
              elevation={2}
              sx={{
                p: 3,
                borderRadius: 2,
                flex: "1 1 250px",
                maxWidth: "350px",
                backgroundColor: "background.paper",
              }}
            >
              <LockOutlined
                sx={{ fontSize: 40, color: "primary.main", mb: 2 }}
              />
              <Typography variant="h6" sx={{ mb: 2 }}>
                2. Set Your Password
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Click the link in the email to create a secure password for your
                account.
              </Typography>
            </Paper>

            <Paper
              elevation={2}
              sx={{
                p: 3,
                borderRadius: 2,
                flex: "1 1 250px",
                maxWidth: "350px",
                backgroundColor: "background.paper",
              }}
            >
              <CheckCircleOutlined
                sx={{ fontSize: 40, color: "primary.main", mb: 2 }}
              />
              <Typography variant="h6" sx={{ mb: 2 }}>
                3. Get Started
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Once your password is set, you can log in and start exploring
                the platform.
              </Typography>
            </Paper>
          </Box>

          {/* Email Information */}
          {urlMail && (
            <Paper
              elevation={2}
              sx={{
                p: 4,
                mt: 2,
                borderRadius: 2,
                backgroundColor: "background.paper",
                textAlign: "center",
              }}
            >
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Reset link has been sent to:
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  display: "inline-block",
                  backgroundColor: "action.hover",
                  py: 1,
                  px: 3,
                  borderRadius: 1,
                  fontWeight: 500,
                }}
              >
                {urlMail}
              </Typography>
            </Paper>
          )}
        </Box>
      </Container>

      {/* Original Dialog */}
      <Dialog
        open={openNewUserForgotPass}
        onClose={handleCloseForgotPass}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 3,
          sx: { borderRadius: 2, p: 2 },
        }}
      >
        <DialogTitle sx={{ pb: 1, textAlign: "center" }}>
          {!isEmailSent ? (
            <Box>
              <Typography
                variant="h5"
                color="primary"
                sx={{ fontWeight: 600, mb: 2 }}
              >
                Welcome to Our Platform!
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                You've been invited to join our platform. To get started, we'll
                help you set up your password.
              </Typography>
            </Box>
          ) : null}
          <IconButton
            aria-label="close"
            onClick={handleCloseForgotPass}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              py={3}
              gap={2}
            >
              <CircularProgress />
              <Typography variant="body1" color="text.secondary">
                Preparing your account setup...
              </Typography>
            </Box>
          ) : isEmailSent ? (
            <Box sx={{ textAlign: "center", py: 3 }}>
              <Box sx={{ height: "10rem", mb: 3 }}>
                <img
                  src={emailSend}
                  alt="Email Sent"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Typography
                variant="h6"
                color="primary.main"
                sx={{ pb: 2, fontWeight: "600" }}
              >
                You're Almost There!
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                We've sent a password reset link to your email address.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  backgroundColor: "info.lighter",
                  p: 2,
                  borderRadius: 1,
                  color: "info.dark",
                }}
              >
                Didn't receive the email? Check your spam folder or contact
                support for assistance.
              </Typography>
            </Box>
          ) : (
            <Box sx={{ textAlign: "center", py: 3 }}>
              <Typography variant="body1" color="error" sx={{ mb: 2 }}>
                Failed to send reset link. Please try again.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                If the problem persists, please contact our support team for
                assistance.
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NewUserPage;
