import React, { createContext, useState, useContext } from "react";
import {
  Dialog,
  DialogActions,
  Slide,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { pageScrollStyle } from "../styles/styles";

// Default transition
const TransitionSlide = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogProps, setDialogProps] = useState({});

  const showDialog = (content, props = {}) => {
    setDialogContent(content);
    setDialogProps(props);
    setOpen(true);
  };

  const closeDialog = () => setOpen(false);

  return (
    <DialogContext.Provider value={{ showDialog, closeDialog }}>
      {children}
      <Dialog
        className="no-tooltip"
        open={open}
        onClose={dialogProps.disableBackdropClose ? undefined : closeDialog}
        fullScreen={dialogProps.fullScreen || false}
        TransitionComponent={dialogProps.transition || TransitionSlide}
        PaperProps={{
          sx: {
            width: dialogProps.width ? dialogProps.width : "80%",
            maxWidth: dialogProps.width ? dialogProps.width : null,
            padding: 0,
            display: "flex",
            flexDirection: "column",
            ...pageScrollStyle,
          },
        }}
        {...dialogProps}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "0.5rem",
              bgcolor: "primary.main",
              position: "sticky",
              top: 0,
              zIndex: 10,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography
                variant="body1"
                color="white.main"
                sx={{
                  fontSize: "1rem",
                  whiteSpace: "nowrap",
                }}
              >
                {dialogProps.title}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <IconButton
                edge="start"
                color="white"
                aria-label="close"
                onClick={closeDialog}
              >
                <CloseRounded />
              </IconButton>
            </Box>
          </Box>
          <Box>{dialogContent}</Box>
          {dialogProps.actions && (
            <DialogActions
              sx={{
                borderTop: "1px solid #ddd",
                position: "sticky",
                bottom: 0,
                backdropFilter: "blur(10px)",
                display: "flex",
                gap: "1rem",
                zIndex: 1500,
              }}
            >
              {dialogProps.actions}
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </DialogContext.Provider>
  );
};

// Custom Hook for easier access
export const useDialog = () => useContext(DialogContext);
